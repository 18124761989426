import '../../../../scss/components/_list-header.scss';

import { Button, DeleteModal, Status, Toggle } from '@finpay-development/shared-components';
import GetAppIcon from '@mui/icons-material/GetApp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Divider, Grid, IconButton, Menu, MenuItem, Tooltip, Typography, CircularProgress, LinearProgress, Skeleton } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState, useMemo, memo, useCallback } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { getClient } from '../../../../implementation-specialist/state/clients/implementation-clients-thunk';
import AccessControl from '../../../../security/components/access-control';
import { RolePageNames } from '../../../../security/model/role-page-names';
import { formatNumberToUSD } from '../../../../shared/calculators';
import { StatusColors } from '../../../../shared/enums';
import { ClientBusinessRules } from '../../../../shared/model/client';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import { Utils } from '../../../../shared/utils';
import {
  setTransactionsCSVData
} from '../../../state/patient-slice';
import { releaseCharge, resendPaymentReceipt, resendRefundReceipt, getLedger } from '../../../state/patient-thunk';
import { Payment, PaymentStatusType, V2DisputeDetail, V2PaymentDetail, V2PFRAdjustmentDetail, V2ReversalDetail } from '../../models/payment';
import { PAYMENT_METHOD_TYPES } from '../../models/payment-method';
import { PaymentStatus } from '../../models/payment-status';
import { PAYMENT_TYPES } from '../../models/payment-type';
import { useNavigate } from 'react-router';
import ResendReceiptModal from './resend-receipt-modal';
import { Ledger, LedgerData, DetailedLedger } from '../../../../shared/model/ledger';
import TransactionMenu from '../../../../shared/transaction-menu';

// Define interface for API response
export interface GetLedgerResponse {
  selectedPatientEncounter: {
    encounterLedger: LedgerData;
  };
}

interface LedgerAccordionContentsProps {
  isAccountHolder?: boolean,
  limit: number,
  isFetchingLedgerDetails: boolean,
  fetchLedger: (isRefresh: boolean) => Promise<void>
}

export const LedgerAccordionContents = memo((props: LedgerAccordionContentsProps) => {
  const { isAccountHolder = false, limit, isFetchingLedgerDetails, fetchLedger } = props;
  const [ledgerOffset, setLedgerOffset] = useState(limit);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [showDetailedTransactions, setShowDetailedTransactions] = useState(false);
  const selectedEncounter = useSelector((state: RootState) => state.patientContext.selectedEncounter);
  const selectedEncounterLedger = useSelector((state: RootState) => state.patientContext.selectedEncounter?.encounterLedger);
  const pendingRequestsRef = useRef<{[key: number]: boolean}>({});
  const [currentBatchIndex, setCurrentBatchIndex] = useState(0);
  const [hasMoreBatches, setHasMoreBatches] = useState(true);
  const unprocessedRecordsRef = useRef<Ledger[]>([]);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const [isAccordionVisible, setIsAccordionVisible] = useState(false);
  const allDetailsCompletedRef = useRef(false);

  // State variables for menu and modal handling
  const [transactionId, setTransactionId] = useState(0);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
  
  const navigate = useNavigate();
  const [optionTexts, setOptionTexts] = useState(
    {
      menuText: "Refund",
      deleteModalTitle: "Refund",
    }
  );
  
  const resendReceiptButton = {
    menuText: "Resend Receipt",
    refundMenuText: "Resend Refund Receipt",
    modalTitle: "Receipt"
  };

  // Get detailed ledger from Redux store
  const detailedLedger = useMemo(() => {
    return selectedEncounterLedger?.ledger || [];
  }, [selectedEncounterLedger?.ledger]) as DetailedLedger[];

  // Check if all details are loaded from individual record flags
  const allDetailsLoaded = useMemo(() => {
    return detailedLedger.length > 0 && detailedLedger.every(item => item.detailsLoaded);
  }, [detailedLedger]);

  // Count of records with details loaded
  const loadedDetailsCount = useMemo(() => {
    return detailedLedger.filter(item => item.detailsLoaded).length;
  }, [detailedLedger]);

  // Notify parent component when all details are complete
  useEffect(() => {
    if (allDetailsLoaded && detailedLedger.length > 0 && !allDetailsCompletedRef.current) {
      allDetailsCompletedRef.current = true;
      console.log("Accordion: All ledger details fetched and processed:", detailedLedger);
    }
  }, [detailedLedger, allDetailsLoaded]);

  useEffect(() => {
    // Reset all tracking mechanisms when IoC changes
    setCurrentBatchIndex(0);
    setHasMoreBatches(true);
    unprocessedRecordsRef.current = [];
    setIsAccordionExpanded(false);
    setIsAccordionVisible(false);
    
    if (selectedEncounterLedger?.ledger && Array.isArray(selectedEncounterLedger.ledger)) {
      // Store all records for later processing when visible
      unprocessedRecordsRef.current = [...selectedEncounterLedger.ledger];
    }
    
    // Clean up function to cancel any pending requests when IoC changes
    return () => {
      setIsAccordionExpanded(false);
      setIsAccordionVisible(false);
    };
  }, [selectedEncounterLedger]);

  const formRef: any = useRef();

  const stateFields = {
    isTransactionsFetchError: useSelector((state: RootState) => {
      return state.patientContext.isError.patientTransactions
    }),
    transactionsCSVData: useSelector((state: RootState) => {
      return state.patientContext.transactionsCSVData
    }),
    currentClientBusinessRule: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.currentClientBusinessRules as ClientBusinessRules
    }),
    missingAddressError: useSelector((state: RootState) => state.patientContext.isError.missingAddress),
    isReleaseChargeError: useSelector((state: RootState) => {
      return state.patientContext.isError.releaseCharge
    }),
    releasePaymentStatusMsg: useSelector((state: RootState) => {
      return state.patientContext.releasePaymentStatusMsg
    }),
    selectedPatient: useSelector((state: RootState) => {
      return state.patientContext.selectedPatient
    }),
    allClients: useSelector((state: RootState) => {
      return state.implementationContext?.implementationSpecialistClient
        ?.allClients
    }),
    selectedPatientEncounter: useSelector((state: RootState) => {
      return state.patientContext.selectedEncounter
    })
  }
  const {isTransactionsFetchError, transactionsCSVData, currentClientBusinessRule, missingAddressError, releasePaymentStatusMsg,selectedPatient, selectedPatientEncounter} = stateFields;

  const { adjustedPFR } = selectedEncounter;
  const patientPaymentProgram = selectedEncounter?.patientPaymentProgram?.[selectedEncounter.patientPaymentProgram.length - 1];
  const dispatch = useDispatch<AppDispatch>();

  const getSelectedPayment = () => {
    const foundPayment = detailedLedger.find(
      (ledgerItem) => 
        ledgerItem.payment?.paymentId === transactionId
    )?.payment;
    
    return foundPayment as unknown as Payment;
  }

  useEffect(() => {
    if (currentClientBusinessRule?.clientId !== selectedEncounter?.clientId && !isAccountHolder) {
      dispatch(getClient(selectedEncounter.clientId));
    }
  }, [dispatch, selectedEncounter?.clientId, currentClientBusinessRule?.clientId, isAccountHolder])

  const handleRefreshTransactions = async () => {
    await fetchLedger(true);
  }

  const disallowRefund = currentClientBusinessRule?.refundsIssuedBy?.toLowerCase() === 'client' && selectedEncounter?.workflow?.workflowId === 3
  async function handleReleaseFunds() {
    setIsRefundModalOpen(false);
    await dispatch(releaseCharge({patientId: selectedEncounter?.patientId, encounterId: selectedEncounter?.patientEncounterId, payment: getSelectedPayment()}))
    handleRefreshTransactions()
  }

  const handlePatientLedgerClick = (clientId: number, patientId: number) => {
    window.open(`/specialist/dashboard/client/${clientId}/patient/${patientId}/ledger`, '_blank');
  };

  const handleFailedResponse = (externalResponse: string) => {
      let toolTipTitle: string;
      if (externalResponse.startsWith('{')) {
          const parsedExternalResponse = JSON.parse(externalResponse);
          toolTipTitle = `${parsedExternalResponse.message} - ${parsedExternalResponse.code}:${parsedExternalResponse.declineCode}`;
      } else {
        toolTipTitle = externalResponse;
      }
      return toolTipTitle;
  };

  const getStatusTextReversalItem = (paymentTypeId: number, paymentMethodTypeId: number, isCaptured: boolean, paymentStatus: string) => {
    const paymentSuccessful = paymentStatus === 'Success'
    let text = 'Refunded';
    if ((paymentMethodTypeId!==2) && 
      (!
        isCaptured)) 
      {
        text = 'Released'
      } else if ((paymentMethodTypeId===2 && (!isCaptured)) || (paymentTypeId === PAYMENT_TYPES.AT_FACILITY.paymentTypeId))
      {
        text = 'Removed'
      }
    if (selectedEncounter?.workflow?.workflowId === 3 && paymentMethodTypeId===2 && isCaptured && !paymentSuccessful) {
      text = "Cancelled"
    }
    return text;
  }

  const showStatus = (payment: V2PaymentDetail) => true;

  const getStatusText = (transaction: DetailedLedger, showDetailedTransactions?: boolean) => {
    if (transaction?.payment?.paymentMethodTypeId === 1) {
      if (transaction?.payment?.paymentStatus.toLowerCase() === "pending") {
        return transaction?.payment?.isCaptured ? "Pending" : "Uncaptured"
      } else if (transaction?.payment?.paymentStatus === "Failed") {
        return "Failed"
      } else if (transaction?.payment?.paymentStatus === "Success") {
        return transaction?.payment?.isCaptured ? "Success" : "Uncaptured"
      }
    } else if ((transaction?.payment?.paymentTypeId === PAYMENT_TYPES.RECURRING.paymentTypeId) && (!(transaction?.payment?.paymentMethodTypeId === 1) && !(transaction?.payment?.paymentMethodTypeId === 2))) {
      return "Failed"
    } else {
      return transaction?.payment?.paymentStatus
    }
    return '';
  }

  const transactionSuccess = Array.isArray(detailedLedger) ? detailedLedger.map((transaction) => {
    const paymentStatus = transaction.payment?.paymentStatus !== PaymentStatus.failed;
    const paymentReversalIdExists = transaction.reversal?.paymentReversalId !== null;
    return paymentStatus || paymentReversalIdExists;
  }) : [];

  const reversalIdArray = Array.isArray(detailedLedger)
    ? detailedLedger
      .filter((item): item is DetailedLedger => !!item && !!item.reversal)
      .map(item => item.reversal?.paymentReversalId)
      .filter((id): id is number => id !== undefined)
    : [];

  const reversalPaymentOriginArray = Array.isArray(detailedLedger)
    ? detailedLedger
      .filter((item): item is DetailedLedger => !!item && !!item.reversal)
      .map(item => item.reversal?.paymentId)
      .filter((id): id is number => id !== undefined)
    : [];

  const disputeIdArray = Array.isArray(detailedLedger)
    ? detailedLedger
      .filter((item): item is DetailedLedger => !!item && !!item.dispute)
      .map(item => item.dispute?.paymentDisputeId)
      .filter((id): id is number => id !== undefined)
    : [];

  const paymentIdArray = Array.isArray(detailedLedger)
    ? detailedLedger
      .filter((item): item is DetailedLedger => !!item && !!item.payment)
      .map(item => item.payment?.paymentId)
      .filter((id): id is number => id !== undefined)
    : [];

  const handleResendReceipt = (receiptEmail: string) => {
    if (paymentIdArray.includes(transactionId)) {
        dispatch(resendPaymentReceipt({ 
            transactionId, 
            receiptEmail 
        }));
    } 
    else if (reversalIdArray.includes(transactionId)) {
        dispatch(resendRefundReceipt({ 
            transactionId, 
            receiptEmail 
        }));
    } 
    else {
        console.error('Transaction ID not found in either array, or payment status is invalid.');
    }
    setIsReceiptModalOpen(false);
  }

  const transactionsHeader = useMemo(() => (
    <Grid
      container
      spacing={2}
      className="pb-3 px-0"
      style={{ marginLeft: "-12px" }}
    >
      <Grid 
        container 
        className="mx-1 pl-3 px-1 py-1 mt-3 header"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Grid item xs={2}>
          <Typography variant="h4" style={{ minHeight: '24px', display: 'flex', alignItems: 'center' }}>
            Initiated Date
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h4" style={{ minHeight: '24px', display: 'flex', alignItems: 'center' }}>
            Type
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h4" className="pl-3" style={{ minHeight: '24px', display: 'flex', alignItems: 'center' }}>
            Status / Source
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h4" style={{ minHeight: '24px', display: 'flex', alignItems: 'center', paddingLeft: '35px' }}>
            Amount / ID
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h4" style={{ minHeight: '24px', display: 'flex', alignItems: 'center', paddingLeft: '10px'}}>
            Transaction Type
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  ), []);

  const pfrAdjustmentsHeader = (
    <Grid container className="header mb-5 pb-2" spacing={2}>
      <Grid item xs={5}/>
      <Grid item xs={3}>
        <Typography variant="h4">
          PFR Adjustments
        </Typography>
      </Grid>
      <Grid item xs={4}/>
    </Grid>
  );

  const pfrAdjustmentListItem = (pfrAdjustment: V2PFRAdjustmentDetail, index: number) => { 
    let arrayDate = Utils.convertISODate(new Date(pfrAdjustment.adjustmentDt), showDetailedTransactions).split(" ")
    const shortDate = arrayDate[0];
    const fullDate = `${arrayDate[1]} ${arrayDate[2]}`;
    return (
    <Grid container spacing={2} className="mx-0 px-1" key={index} data-testid="ledger-pfr-children">
      <Grid item xs={2}>
        <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
          {shortDate}
        </Typography>
        {showDetailedTransactions && (
          <Typography variant="body2" className="pfr-full-date" style={{ display: 'flex', alignItems: 'center' }}>
            {fullDate}
          </Typography>
        )}
      </Grid>
      <Grid item xs={1}>
        <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
          PFR
        </Typography>
      </Grid>
      <Grid item xs={2}>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', paddingLeft: '15px' }}>
          {formatNumberToUSD(pfrAdjustment?.adjustmentAmt)}
        </Typography>
        {showDetailedTransactions && (
          <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', paddingLeft: '15px' }}>
            {pfrAdjustment?.patientPFRAdjustmentId}
          </Typography>
        )}
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
          Adjustment
        </Typography>
      </Grid>
    </Grid>
    )
  };

  const balanceInfo = useMemo(() => (
    <Grid
      container
      spacing={2}
      className="px-2"
    >
      <Grid item xs={12} className="mt-6">
        <Divider />
      </Grid>
      <Grid item xs={8} className="">
        <Typography variant="subtitle2">
          Estimated PFR
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="flex-end" className="">
          <Typography variant="subtitle2">
            {formatNumberToUSD(selectedEncounter?.pfrAmt)}
          </Typography>
        </Box>
      </Grid>
      {(detailedLedger && detailedLedger.some(item => item?.pfrAdjustment?.patientPFRAdjustmentId)) &&
        <>
          <Grid item xs={8} className="">
            <Typography variant="subtitle2">
              Adjusted PFR
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="flex-end" className="">
              <Typography variant="subtitle2">
                {formatNumberToUSD(adjustedPFR)}
              </Typography>
            </Box>
          </Grid>
        </>
      }
      <Grid item xs={8} className="">
        <Typography variant="subtitle2" test-id="pending-remaining-balance">
          Pending Remaining Balance
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="flex-end" className="">
          <Typography variant="subtitle2">
            {formatNumberToUSD(patientPaymentProgram?.patientPaymentSchedule.pfrPendingBalance)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} className="">
        <Typography variant="subtitle2" test-id="remaining-balance">
          Remaining Balance
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="flex-end" className="">
          <Typography variant="subtitle2">
            {formatNumberToUSD(patientPaymentProgram?.patientPaymentSchedule.pfrCapturedBalance)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  ), [
    selectedEncounter?.pfrAmt, 
    adjustedPFR, 
    patientPaymentProgram?.patientPaymentSchedule.pfrPendingBalance,
    patientPaymentProgram?.patientPaymentSchedule.pfrCapturedBalance,
    detailedLedger
  ]);

  const initialValues = {
    showDetailedTransactions: false,
  }

  const validationSchema = Yup.object({
    showDetailedTransactions: Yup.boolean(),
  })

  function checkIfIsValid(value: { showDetailedTransactions: boolean }) {
    setShowDetailedTransactions(value.showDetailedTransactions);
    validationSchema
      .validate(value)
  }

  // const disabledDownloadButton = (<Button
  //     type="text"
  //     icon={<GetAppIcon />}
  //     paddingLeft={0}
  //     disabled
  //   >
  //     Download File (Temporarily Disabled)
  //   </Button>)
  
  const disabledPatientLedgerButton = (
    <IconButton
      color="primary"
      style={{ fontSize: "1.5rem", float: 'right', paddingRight: '6px', paddingTop: '7px' }}
      disabled
    >
      <OpenInNewIcon fontSize="small" style={{paddingRight: '6px'}} /> View Master Patient Ledger
    </IconButton>
  )

  const handleLoadMore = async () => {
    const ledgerItems = selectedEncounterLedger?.ledger || [];
    
    if (selectedPatientEncounter?.patientEncounterId) {
      setIsLoadingMore(true);
      try {
        const response = await dispatch(getLedger({
          encounterId: selectedPatientEncounter?.patientEncounterId,
          supressErrors: true,
        })) as { payload: GetLedgerResponse };

        const responseData = response?.payload?.selectedPatientEncounter?.encounterLedger;
        
        if (responseData) {
          const responseLedger = Array.isArray(responseData.ledger) ? responseData.ledger : [];
          
          const newLedgerData: LedgerData = {
            ledger: [...ledgerItems, ...responseLedger],
            pagination: responseData.pagination || { count: ledgerItems.length + responseLedger.length }
          };
          
          dispatch(setTransactionsCSVData(newLedgerData));
          
          setLedgerOffset(prev => prev + limit);
        }
      } catch (error) {
        console.error('Error loading more transactions:', error);
      } finally {
        setIsLoadingMore(false);
      }
    }
  }

  const paymentListItem = useMemo(() => (ledgerItem: DetailedLedger) => {
    // Show loading state for this specific ledger item if it's a payment type and doesn't have details yet
    const isPaymentType = [1, 4, 5, 6].includes(ledgerItem.ledgerTypeId);
    const isItemLoading = !ledgerItem.detailsLoaded && isPaymentType;
    
    // Either the item is loading or it's a payment type but doesn't have payment details yet
    if (isItemLoading || (isPaymentType && !ledgerItem.payment)) {
      let shortDate = "", fullDate = "";
      if(ledgerItem.ledgerDt) {
        let arrayDate = Utils.convertISODate(new Date(ledgerItem.ledgerDt), showDetailedTransactions).split(" ");
        shortDate = arrayDate[0];
        if(showDetailedTransactions)
          fullDate = `${arrayDate[1]} ${arrayDate[2]}`;
      }
      
      return (
        <Grid container spacing={2} className="mx-0 px-1 py-2" data-testid="ledger-payment-loading">
          <Grid item xs={2}>
            <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', minHeight: '24px' }}>{shortDate}</Typography>
            <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', minHeight: '24px' }}>
              {showDetailedTransactions && fullDate}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Skeleton variant="text" width="70%" height={24} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton variant="text" width="80%" height={24} />
            {showDetailedTransactions && (
              <Skeleton variant="text" width="60%" height={20} />
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', minHeight: '24px', paddingLeft: '15px' }}>
              {formatNumberToUSD(ledgerItem?.ledgerAmt)}
            </Typography>
            {showDetailedTransactions && (
              <Skeleton variant="text" width="70%" height={20} style={{ marginLeft: '15px' }} />
            )}
          </Grid>
          <Grid item xs={2}>
            <Skeleton variant="text" width="70%" height={24} />
          </Grid>
        </Grid>
      );  
    }
    
    const { payment } = ledgerItem;
    const isTransactionFailed = payment?.paymentStatus === PaymentStatus.failed;
    let shortDate = "", fullDate = "";
    if(ledgerItem.ledgerDt) {
      let arrayDate = Utils.convertISODate(new Date(ledgerItem.ledgerDt), showDetailedTransactions).split(" ");
      shortDate = arrayDate[0];
      if(showDetailedTransactions)
        fullDate = `${arrayDate[1]} ${arrayDate[2]}`;
    }
    const wasPaymentMethodDeleted = (ledgerItem?.payment?.paymentTypeId === PAYMENT_TYPES.RECURRING.paymentTypeId) && (ledgerItem?.payment?.paymentMethodTypeId!==1 && ledgerItem?.payment?.paymentMethodTypeId!==2);
    const hideMenu = getStatusText(ledgerItem) === 'Uncaptured' && payment?.isFullyRefunded;

    return (
      <Grid container spacing={2} className="mx-0 px-1" data-testid="ledger-payment-children">
        <Grid item xs={2}>
          <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>{shortDate}</Typography>
          {showDetailedTransactions && (
            <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
              {fullDate}
            </Typography>
          )}
        </Grid>
        <Grid item xs={1}>
          <Typography variant="body2" color='textSecondary' style={{ display: 'flex', alignItems: 'center' }}>
            {payment ? Utils.getPaymentTypeName(payment) : 'Payment'}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <>
          {/* This section should never be reached if the item is still loading, but add a safety check */}
          {!ledgerItem.detailsLoaded || !payment ? (
            <Typography variant="body2" className="pl-3" style={{ display: 'flex', alignItems: 'center' }}>
              <Status
                text="Loading details..."
                statusColor={StatusColors.warning}
              />
            </Typography>
          ) : showStatus(payment as V2PaymentDetail) && (
            <Typography variant="body2" className="pl-3" data-testid="payment-status-text">
              {(payment?.paymentStatus === 'Failed' && payment?.externalResponse) ? (
                <Tooltip
                  title={handleFailedResponse(payment?.externalResponse)}
                >
                  <div>
                    <Status
                      text={
                        getStatusText(
                          ledgerItem
                        ) || ''
                      }
                      statusColor={
                        (payment?.paymentStatus === "Success" as PaymentStatusType) &&
                        payment?.isCaptured &&
                        !releasePaymentStatusMsg.errorMsg
                            ? StatusColors.success
                            : (payment?.paymentStatus === "Pending" as PaymentStatusType ||
                                  !payment?.isCaptured) &&
                              !(payment?.paymentStatus === "Failed" as PaymentStatusType)
                            ? StatusColors.warning
                            : StatusColors.error
                    }
                    />
                  </div>
                </Tooltip>
                ) : (
                  <Status
                    text={
                      getStatusText(
                        ledgerItem
                      ) || ''
                    }
                    statusColor={
                      (payment?.paymentStatus === "Success") &&
                        payment?.isCaptured &&
                        !releasePaymentStatusMsg.errorMsg
                            ? StatusColors.success
                            : (payment?.paymentStatus === "Pending" ||
                                  !payment?.isCaptured) &&
                              !(payment?.paymentStatus === "Failed")
                            ? StatusColors.warning
                            : StatusColors.error
                    }
                  />
              )}
            </Typography>
          )}
        {showDetailedTransactions && wasPaymentMethodDeleted && (
            <Typography variant="body2" color="error">
                No Recurring Payment Method in Stripe
            </Typography>
        )}
          </>
          {showDetailedTransactions && !wasPaymentMethodDeleted && (
            <Typography
              variant="body2"
              className="pl-2"
              color='textSecondary'
              data-testid="payment-card-info"
            >
              {Utils.getLedgerDetails(ledgerItem?.payment?.paymentMethodTypeId!, ledgerItem?.payment?.paymentTypeId!, ledgerItem?.ledgerAmt!, ledgerItem?.payment?.last4)}
            </Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', paddingLeft: '15px' }}>
            {formatNumberToUSD(ledgerItem?.ledgerAmt)}
          </Typography>
          {showDetailedTransactions && (
            <Typography variant="body2" className="break-word" style={{ display: 'flex', alignItems: 'center', paddingLeft: '15px' }}>
              {Utils.getExternalPaymentId(ledgerItem?.payment)}
            </Typography>
          )}
        </Grid>
        <Grid item xs={isAccountHolder ? 3 : 2}>
          <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', paddingLeft: '1px'}}>
            {Utils.getTransactionType(ledgerItem?.payment?.paymentTypeId!, ledgerItem?.ledgerAmt!)}
          </Typography>
        </Grid>
        {(ledgerItem?.payment?.paymentId && !isTransactionFailed && !disallowRefund && !hideMenu) ? ( 
          <Grid item xs={1} style={{marginTop: -12, maxHeight: 10, paddingLeft: '1px'}}>
            <TransactionMenu 
              transactionData={{
                transactionId: ledgerItem.payment.paymentId,
                isPaymentTypeCard: ledgerItem.payment.paymentMethodTypeId === PAYMENT_METHOD_TYPES.CARD.paymentMethodTypeId,
                paymentStatus: ledgerItem.payment.paymentStatus,
                paymentTypeId: ledgerItem.payment.paymentTypeId,
                isPaymentCaptured: ledgerItem.payment.isCaptured,
              }}
              detailedLedger={detailedLedger}
              reversalIdArray={reversalIdArray}
              reversalPaymentOriginArray={reversalPaymentOriginArray}
              disputeIdArray={disputeIdArray}
              disallowRefund={disallowRefund}
              isAccountHolder={isAccountHolder}
              workflowId={selectedEncounter?.workflow?.workflowId}
              onRefund={(transactionId, menuTexts) => {
                setOptionTexts(menuTexts);
                setTransactionId(transactionId);
                setIsRefundModalOpen(true);
              }}
              onResendReceipt={(transactionId, isRefund) => {
                setTransactionId(transactionId);
                setIsReceiptModalOpen(true);
              }}
              onNavigateToDispute={(disputeId) => {
                navigate(`/specialist/dashboard/dispute/${disputeId}`);
              }}
              resendReceiptButton={resendReceiptButton}
            />
          </Grid>
        ) : null}
      </Grid>
    );
  }, [
    showDetailedTransactions, 
    releasePaymentStatusMsg, 
    isAccountHolder, 
    disallowRefund,
    getStatusText,
    showStatus,
    handleFailedResponse,
    navigate,
    detailedLedger,
    reversalIdArray,
    reversalPaymentOriginArray,
    disputeIdArray,
    resendReceiptButton,
    selectedEncounter?.workflow?.workflowId
  ]);

  const paymentDisputeListItem = useMemo(() => (ledgerItem: DetailedLedger) => {
    // Show loading state for this specific ledger item if it's a dispute type and doesn't have details yet
    const isDisputeType = [3].includes(ledgerItem.ledgerTypeId);
    const isItemLoading = !ledgerItem.detailsLoaded && isDisputeType;
    
    // Either the item is loading or it's a dispute type but doesn't have dispute details yet
    if (isItemLoading || (isDisputeType && !ledgerItem.dispute)) {
      let shortDate = "", fullDate = "";
      if(ledgerItem.ledgerDt) {
        let arrayDate = Utils.convertISODate(new Date(ledgerItem.ledgerDt), showDetailedTransactions).split(" ");
        shortDate = arrayDate[0];
        if(showDetailedTransactions)
          fullDate = `${arrayDate[1]} ${arrayDate[2]}`;
      }
      
      return (
        <Grid container spacing={2} className="mx-0 px-1 py-2" data-testid="ledger-dispute-loading">
          <Grid item xs={2}>
            <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', minHeight: '24px' }}>{shortDate}</Typography>
            <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', minHeight: '24px' }}>
              {showDetailedTransactions && fullDate}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Skeleton variant="text" width="70%" height={24} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton variant="text" width="80%" height={24} />
            {showDetailedTransactions && (
              <Skeleton variant="text" width="60%" height={20} />
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', minHeight: '24px', paddingLeft: '15px' }}>
              {formatNumberToUSD(ledgerItem?.ledgerAmt)}
            </Typography>
            {showDetailedTransactions && (
              <Skeleton variant="text" width="70%" height={20} style={{ marginLeft: '15px' }} />
            )}
          </Grid>
          <Grid item xs={2}>
            <Skeleton variant="text" width="70%" height={24} />
          </Grid>
        </Grid>
      );
    }
    const { dispute } = ledgerItem;
    const { paymentMethodTypeId, paymentTypeId } = dispute!   
    let shortDate = "", fullDate = "";
    if (dispute?.disputeCloseDt || ledgerItem?.createDt) {
      let arrayDate = Utils.convertISODate(new Date(dispute?.disputeCloseDt || ledgerItem?.createDt), showDetailedTransactions).split(" ");
      shortDate = arrayDate[0];
      if (showDetailedTransactions) {
        fullDate = `${arrayDate[1]} ${arrayDate[2]} `;
      }
    }
  
    return (
      <Grid container spacing={2} className="mx-0 px-1" data-testid="ledger-dispute-children">
        <Grid item xs={2}>
          <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>{shortDate}</Typography>
          {showDetailedTransactions && (
            <Typography variant="body2" data-testid="dispute-full-date" style={{ display: 'flex', alignItems: 'center' }}>
              {fullDate}
            </Typography>
          )}
        </Grid>
        <Grid item xs={1}>
          <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
            {ledgerItem.dispute ? 'Dispute' : ''}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" className="pl-3" style={{ display: 'flex', alignItems: 'center' }}>
            <Status
              text={isAccountHolder ? 'Dispute Won' : 'Dispute Lost'}
              statusColor={isAccountHolder ? StatusColors.success : StatusColors.error}
            />
          </Typography>
          {showDetailedTransactions && (
            <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
              {'Reason: ' + ledgerItem?.dispute?.disputeReason}
            </Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="error" style={{ display: 'flex', alignItems: 'center', paddingLeft: '15px' }}>
            {formatNumberToUSD(ledgerItem?.dispute?.disputeAmt)}
          </Typography>
          {showDetailedTransactions && (
            <Typography variant="body2" className="break-word" style={{ display: 'flex', alignItems: 'center', paddingLeft: '15px' }}>
              {ledgerItem?.dispute?.externalDisputeId}
            </Typography>
          )}
        </Grid>
        <Grid item xs={isAccountHolder ? 3 : 2}>
          <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
            {ledgerItem?.dispute?.paymentTypeId && ledgerItem?.dispute?.disputeAmt
              ? Utils.getTransactionType(ledgerItem.dispute.paymentTypeId, ledgerItem.dispute.disputeAmt)
              : ''}
          </Typography>
        </Grid>
        {(ledgerItem?.dispute?.paymentDisputeId && !isAccountHolder) && (
          <Grid item xs={1} style={{marginTop: -12, maxHeight: 10, paddingLeft: '1px'}}>
            <TransactionMenu 
              transactionData={{
                transactionId: ledgerItem?.dispute?.paymentDisputeId,
                // paymentId: paymentId,
                isPaymentTypeCard: paymentMethodTypeId === PAYMENT_METHOD_TYPES.CARD.paymentMethodTypeId,
                // paymentStatus: paymentStatus,
                paymentTypeId: paymentTypeId,
                isPaymentCaptured: false,
                paymentMethodTypeId: paymentMethodTypeId,
                isCaptured: false
              }}
              detailedLedger={detailedLedger}
              reversalIdArray={reversalIdArray}
              reversalPaymentOriginArray={reversalPaymentOriginArray}
              disputeIdArray={disputeIdArray}
              disallowRefund={disallowRefund}
              isAccountHolder={isAccountHolder}
              workflowId={selectedEncounter?.workflow?.workflowId}
              onRefund={(transactionId, menuTexts) => {
                setOptionTexts(menuTexts);
                setTransactionId(transactionId);
                setIsRefundModalOpen(true);
              }}
              onResendReceipt={(transactionId, isRefund) => {
                setTransactionId(transactionId);
                setIsReceiptModalOpen(true);
              }}
              onNavigateToDispute={(disputeId) => {
                navigate(`/specialist/dashboard/dispute/${disputeId}`);
              }}
              resendReceiptButton={resendReceiptButton}
            />
          </Grid>
        )}
      </Grid>
    );
  }, [
    showDetailedTransactions, 
    isAccountHolder,
    Utils
  ]);

  const reversalListItem = useMemo(() => (ledgerItem: DetailedLedger) => {
    // Show loading state for this specific ledger item if it's a reversal type and doesn't have details yet
    const isReversalType = [2].includes(ledgerItem.ledgerTypeId);
    const isItemLoading = !ledgerItem.detailsLoaded && isReversalType;
    
    // Either the item is loading or it's a reversal type but doesn't have reversal details yet
    if (isItemLoading || (isReversalType && !ledgerItem.reversal)) {
      let shortDate = "", fullDate = "";
      if(ledgerItem.ledgerDt) {
        let arrayDate = Utils.convertISODate(new Date(ledgerItem.ledgerDt), showDetailedTransactions).split(" ");
        shortDate = arrayDate[0];
        if(showDetailedTransactions)
          fullDate = `${arrayDate[1]} ${arrayDate[2]}`;
      }
      
      return (
        <Grid container spacing={2} className="mx-0 px-1 py-2" data-testid="ledger-reversal-loading">
          <Grid item xs={2}>
            <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', minHeight: '24px' }}>{shortDate}</Typography>
            <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', minHeight: '24px' }}>
              {showDetailedTransactions && fullDate}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Skeleton variant="text" width="70%" height={24} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton variant="text" width="80%" height={24} />
            {showDetailedTransactions && (
              <Skeleton variant="text" width="60%" height={20} />
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', minHeight: '24px', paddingLeft: '15px' }}>
              {formatNumberToUSD(ledgerItem?.ledgerAmt)}
            </Typography>
            {showDetailedTransactions && (
              <Skeleton variant="text" width="70%" height={20} style={{ marginLeft: '15px' }} />
            )}
          </Grid>
          <Grid item xs={2}>
            <Skeleton variant="text" width="70%" height={24} />
          </Grid>
        </Grid>
      );
    }
    
    const { reversal } = ledgerItem;
    
    if (!reversal) return null;
    
    const { paymentTypeId, paymentMethodTypeId, isCaptured, paymentStatus, reversalAmt, reversalDate, paymentReversalId, paymentId } = reversal as V2ReversalDetail;
    
    let shortDate = "", fullDate = "";
    if(reversalDate) {
      let arrayDate = Utils.convertISODate(new Date(reversalDate), showDetailedTransactions).split(" ");
      shortDate = arrayDate[0];
      if(showDetailedTransactions)
        fullDate = `${arrayDate[1]} ${arrayDate[2]} `;
    }
    
    return (
      <Grid container spacing={2} className="mx-0 px-1" data-testid="ledger-reversal-children">
        <Grid item xs={2}>
          <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
            {shortDate}
          </Typography>
          {showDetailedTransactions && (
            <Typography variant="body2" data-testid="reversal-full-date" style={{ display: 'flex', alignItems: 'center' }}>
              {fullDate}
            </Typography>
          )}
        </Grid>
        <Grid item xs={1}>
          <Typography variant="body2" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}>
            {Utils.getLedgerPaymentTypeName(paymentTypeId, paymentMethodTypeId, reversalAmt)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" className="pl-3" style={{ display: 'flex', alignItems: 'center', minHeight: '24px'}}>
              <Status
                text={getStatusTextReversalItem(paymentTypeId, paymentMethodTypeId, isCaptured, paymentStatus)}
                statusColor={
                  paymentStatus === PaymentStatus.success ? StatusColors.success :
                  (paymentStatus === PaymentStatus.pending ? StatusColors.warning
                  : StatusColors.error)
                }
              />
          </Typography>
        <Typography variant="body2" className="pl-2" style={{ display: 'flex', alignItems: 'center' }}>
            {showDetailedTransactions ?
              Utils.getLedgerDetails(paymentMethodTypeId, paymentTypeId, reversalAmt, reversal?.last4) : ""
            }
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="error" style={{ display: 'flex', alignItems: 'center', paddingLeft: '15px' }}>
            {formatNumberToUSD(reversalAmt && -Math.abs(reversalAmt))}
          </Typography>
          <Typography variant="body2" className="break-word" style={{ display: 'flex', alignItems: 'center', paddingLeft: '15px' }}>
            {showDetailedTransactions && (
              reversal?.externalReversalId
            )}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
            {Utils.getTransactionType(paymentTypeId, reversalAmt)}
          </Typography>
        </Grid>
        {(reversal?.paymentId && !isAccountHolder && getStatusTextReversalItem(paymentTypeId, paymentMethodTypeId, isCaptured, paymentStatus)!=='Removed' && getStatusTextReversalItem(paymentTypeId, paymentMethodTypeId, isCaptured, paymentStatus) !== 'Released') && (
          <Grid item xs={1} style={{marginTop: -12, maxHeight: 10, paddingLeft: '1px'}}>
            <TransactionMenu 
              transactionData={{
                transactionId: paymentReversalId,
                paymentId: paymentId,
                isPaymentTypeCard: paymentMethodTypeId === PAYMENT_METHOD_TYPES.CARD.paymentMethodTypeId,
                paymentStatus: paymentStatus,
                paymentTypeId: paymentTypeId,
                isPaymentCaptured: isCaptured,
                paymentMethodTypeId: paymentMethodTypeId,
                isCaptured: isCaptured
              }}
              detailedLedger={detailedLedger}
              reversalIdArray={reversalIdArray}
              reversalPaymentOriginArray={reversalPaymentOriginArray}
              disputeIdArray={disputeIdArray}
              disallowRefund={disallowRefund}
              isAccountHolder={isAccountHolder}
              workflowId={selectedEncounter?.workflow?.workflowId}
              onRefund={(transactionId, menuTexts) => {
                setOptionTexts(menuTexts);
                setTransactionId(transactionId);
                setIsRefundModalOpen(true);
              }}
              onResendReceipt={(transactionId, isRefund) => {
                setTransactionId(transactionId);
                setIsReceiptModalOpen(true);
              }}
              onNavigateToDispute={(disputeId) => {
                navigate(`/specialist/dashboard/dispute/${disputeId}`);
              }}
              resendReceiptButton={resendReceiptButton}
            />
          </Grid>
        )}
      </Grid>
    );
  }, [
    showDetailedTransactions, 
    isAccountHolder,
    getStatusTextReversalItem,
    detailedLedger,
    reversalIdArray,
    reversalPaymentOriginArray,
    disputeIdArray,
    disallowRefund,
    navigate,
    resendReceiptButton,
    selectedEncounter?.workflow?.workflowId,
    Utils
  ]);

  const handleAccordionExpand = useCallback(() => {
    // Prevent excessive calls with debounce
    if (!isAccordionExpanded) {
      setIsAccordionExpanded(true);
    }
  }, [isAccordionExpanded]);
  
  // Get the appropriate renderer based on ledger type
  const getLedgerItemRenderer = useCallback((ledgerItem: DetailedLedger, index: number) => {
    if (!ledgerItem) {
        return null; // Skip rendering for null/undefined items
    }
    
    if ([1, 4, 5, 6].includes(ledgerItem.ledgerTypeId)) {
        return (
            <div style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f5f7f9' }}>
                {paymentListItem(ledgerItem)}
            </div>
        );
    } else if ([2].includes(ledgerItem.ledgerTypeId)) {
        return (
            <div style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f5f7f9' }}>
                {reversalListItem(ledgerItem)}
            </div>
        );
    } else if ([3].includes(ledgerItem.ledgerTypeId)) {
        return (
            <div style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f5f7f9' }}>
                {paymentDisputeListItem(ledgerItem)}
            </div>
        );
    } else if ([8].includes(ledgerItem.ledgerTypeId)) {
        // Handle PFR adjustment type - these will be shown in a separate section
        return null;
    }
    
    // For unknown ledger types, don't render anything
    return null;
  }, [paymentListItem, reversalListItem, paymentDisputeListItem]);

  // Helper function to display placeholder skeleton rows during loading
  const renderSkeletonRows = () => {
    return Array(5).fill(0).map((_, index) => (
      <Grid 
        container 
        spacing={2} 
        className="mx-0 px-1 py-2" 
        key={`skeleton-${index}`}
        style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f5f7f9' }}
      >
        <Grid item xs={2}>
          <Skeleton variant="text" width="60%" height={24} />
        </Grid>
        <Grid item xs={1}>
          <Skeleton variant="text" width="80%" height={24} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" width="70%" height={24} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="text" width="50%" height={24} style={{ marginLeft: '15px' }} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" width="60%" height={24} />
        </Grid>
      </Grid>
    ));
  };

  return (
    <div className="w-100">
      
      <div className="pb-7 px-1">
        {/* <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Send Ledger" isAccountHolderPortal={true}
          renderNoAccess={() => (disabledDownloadButton)}
        >
          <Button
            type="text"
            icon={<GetAppIcon />}
            paddingLeft={0}
            disabled
          >
            Download File (Temporarily Disabled)
          </Button>
        </AccessControl> */}
        <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Patient Ledger" isAccountHolderPortal={true}
          renderNoAccess={() => (disabledPatientLedgerButton)}
        >
          <IconButton
              color="primary"
              style={{ fontSize: "1.5rem", float: 'right', paddingRight: '6px', paddingTop: '7px' }}
              onClick={() =>
                handlePatientLedgerClick(selectedEncounter?.clientId, selectedEncounter?.patientId)
              }
            >
              <OpenInNewIcon fontSize="small" style={{paddingRight: '6px'}} /> View Master Patient Ledger
            </IconButton>
        </AccessControl>
        {!isAccountHolder && (
          <Grid container className="my-3 ml-2">
            <Grid xs={12} item>
              <Typography variant="h3" className="mb-2">
                Details
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <Formik
                innerRef={formRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                validate={checkIfIsValid}
                onSubmit={() => {}}
              >
                {(formik) => (
                  <Form>
                    <div className="mb-6" data-testid="show-detailed-transactions">
                      <Toggle
                        name="showDetailedTransactions"
                        formik={formik}
                        value={formik.values.showDetailedTransactions}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        )}
      </div>
      <div className="list-header documents-accordion px-0 pb-4" onClick={handleAccordionExpand}>
        {isTransactionsFetchError ? (
          <Grid container direction="row">
            <Grid item xs={10}>
              <Typography variant="h4">
                Transactions Pending. Use Refresh button to update.
              </ Typography>
            </Grid>
          </Grid>
        ) : (
          <div className="px-1">
            <div className="pl-0">
              {/* Loading progress indicator - positioned above the transactions table */}
              {!allDetailsLoaded && detailedLedger.length > 0 && (
                <Box 
                  sx={{ 
                    width: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center', 
                    mb: 3,
                    mt: 0
                  }}
                >
                  <Typography variant="body2" color="text.secondary" align="center" sx={{ mb: 1 }}>
                    {loadedDetailsCount} of {detailedLedger.length} transactions loaded
                  </Typography>
                  <LinearProgress 
                    sx={{ width: '50%', height: 8, borderRadius: 1 }} 
                    variant="determinate" 
                    value={(loadedDetailsCount / detailedLedger.length) * 100} 
                  />
                </Box>
              )}
              
              {transactionsHeader}
              <div className="striped-row-container" data-testid="ledger-list" style={{ position: 'relative', marginLeft: "-12px", width: "calc(100% + 24px)" }}>
                {detailedLedger.length === 0 ? (
                  // If no items to show yet, show skeleton or empty message
                  isFetchingLedgerDetails ? (
                    // Show skeleton loading when initial data is loading
                    renderSkeletonRows()
                  ) : (
                    // No data available
                    <Typography className="mt-4 mx-0 px-1 py-1" variant="h4">
                      There are no transactions available
                    </Typography>
                  )
                ) : (
                  // We have items to show - show all at once
                  <>
                    {detailedLedger.map((ledgerItem: DetailedLedger, index: number) => (
                      <React.Fragment key={ledgerItem.patientLedgerId || index}>
                        {getLedgerItemRenderer(ledgerItem, index)}
                      </React.Fragment>
                    ))}
                  </>
                )}
              </div>
              {detailedLedger && detailedLedger.some(item => item?.pfrAdjustment?.patientPFRAdjustmentId) && (
                <React.Fragment>
                  <div style={{ marginLeft: "-12px" }}>
                    {pfrAdjustmentsHeader}
                  </div>
                  <div className="striped-row-container" style={{ marginLeft: "-12px", width: "calc(100% + 24px)" }}>
                    {detailedLedger.filter(item => item.pfrAdjustment).map((item, index) => (
                      <div key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f5f7f9' }}>
                        {pfrAdjustmentListItem(item.pfrAdjustment as V2PFRAdjustmentDetail, index)}
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      </div>
      {balanceInfo}
      {isReceiptModalOpen && (
        <ResendReceiptModal
          open={isReceiptModalOpen}
          receiptEmail={selectedPatient?.contact.email}
          titleText={reversalIdArray.includes(transactionId) ? resendReceiptButton.refundMenuText + '?' : resendReceiptButton.menuText + '?'}
          onSend={handleResendReceipt}
          onClose={()=> setIsReceiptModalOpen(false)}
        />
      )}
      {isRefundModalOpen && (
        <DeleteModal
          open={isRefundModalOpen}
          title={optionTexts.deleteModalTitle}
          subTitle="This action cannot be undone"
          okButtonText= {`${optionTexts.deleteModalTitle}`}
          handleDeleteCancel={() => setIsRefundModalOpen(false)}
          handleDeleteOk={handleReleaseFunds}
        />
      )}
    </div>
  );
})