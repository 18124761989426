import {
  emptyPatientDocument,
  emptySortedDocuments,
  PatientDocument,
  SortedDocuments,
} from "./patient-document";
import { emptyPatientInsurance, PatientInsurance } from "./patient-insurance";
import {
  emptyPatientPaymentProgram,
  PatientPaymentProgram,
} from "./patient-payment-program";
import { emptyPatientChampion, PatientChampion } from "./patient-champion";
import {
  PayorRisk,
  TimingRisk,
} from "../../../shared/model/timing-and-payor-risk";
import { emptyWorkflow, Workflow } from "../../../shared/model/client";
import { emptyTimingRisk } from "../../../shared/model/timing-and-payor-risk";
import { emptyPayorRisk } from "../../../shared/model/timing-and-payor-risk";
import { emptyPatientPfr, PatientPfr } from "./patient-pfr";
import {
  emptyStripePaymentMethod,
  StripePaymentMethod,
} from "./stripe-payment-method";
import { emptyTransaction, Transaction } from "./transaction";
import { emptyRiskClass, RiskClass } from "../../../shared/model/risk-class";
import { PFRAdjustment, emptyPFRAdjustment } from "./pfr-adjustment";
import {
  emptyTypeOfCare,
  TypeOfCare,
} from "../../../shared/model/type-of-care";
import { PaymentDispute } from "./payment";
import { LoginVerifiedStatus } from "src/shared/enums";
import { Ledger as LedgerType, LedgerData } from '../../../shared/model/ledger';

export interface PesScriptType {
  clientId: number;
  facilityId: number;
  riskClassId: number;
  scriptUrl: string;
}

export const emptyPesScriptType: PesScriptType = {
  clientId: -1,
  facilityId: -1,
  riskClassId: -1,
  scriptUrl: "",
};
export interface ClientType {
  clientName: string;
  pesScripts: PesScriptType[];
}

export const emptyClientType: ClientType = {
  clientName: "",
  pesScripts: [emptyPesScriptType],
};

export type VerifiedStatuses = null | LoginVerifiedStatus;

export interface PatientEncounter {
  authorizationDocumentStatus: SortedDocuments;
  patientEncounterId: number;
  patientId: number;
  clientId: number;
  clientName: string;
  client: ClientType;
  facilityId: number;
  facilityName: string;
  linkedUserId: string;
  clientsPatientAccountId: string | null;
  clientsPatientIOCId: string | null;
  instanceCreateDt: string;
  levelOfCare?: string;
  pfrAmt: number;
  adjustedPFR?: number;
  doPFRAdjustmentsExist: boolean;
  doDisputesExist: boolean;
  timingRisk: TimingRisk;
  payorRisk: PayorRisk;
  riskClass: RiskClass;
  typeOfCare: TypeOfCare;
  admissionDt: any;
  dischargeDt: any;
  pfrDiscount: number;
  preDiscountPfrAmt: number;
  hasChampions?: boolean; // nullable boolean. must initialize to undefined.
  iocStatus: string;
  iocStatusDt: string;
  capacityToPayId: string;
  finClearanceStatus: string;
  finClearanceStatusDt: string;
  workflow: Workflow;
  isLocked: boolean;
  lastUpdateUserId?: string;
  lastUpdateDt?: string;
  lockedByUserId: string | null;
  lockRequestedById: string | null;
  lockStatus: "DENY" | "RELEASE" | null;
  sectionStatus: string;
  patientInsurance: PatientInsurance[];
  patientChampion: PatientChampion[];
  patientPaymentProgram: PatientPaymentProgram[];
  patientTransactions: Transaction[];
  patientPfrAdjustments: PFRAdjustment[];
  paymentDisputes: PaymentDispute[];
  patientPaymentMethods: StripePaymentMethod[];
  patientDocument: PatientDocument[];
  isConverted: boolean;
  patientPfr: PatientPfr;
  patientContactInfo: patientContact;
  calculatedRemainingBalance?: number | undefined;
  paidToPatient: boolean;
  autoCalcEnabled?: boolean;
  isRiskClassOverriden?: boolean;
  pfrEstimateId?: number;
  isWarmTransferCompleted: boolean;
  loginVerified: VerifiedStatuses;
  adjustedPfrAmt?: number;
  encounterLedger: LedgerData;
}

export const emptyPatientContactInfo = {
  contactId: 0,
  title: "",
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  primaryAddress: {
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: {
      stateCode: "",
      stateId: 0,
      stateName: "",
    },
    zipCode: 0,
  },
  primPhoneNum: "",
  primPhoneType: "",
  secPhoneNum: "",
  secPhoneType: "",
  leaveVMFl: false,
  smsConsentFl: false,
};

export const emptyPatientEncounter: PatientEncounter = {
  authorizationDocumentStatus: emptySortedDocuments,
  patientEncounterId: 0,
  patientId: 0,
  clientId: -1,
  clientName: "",
  client: emptyClientType,
  facilityId: -1,
  facilityName: "",
  linkedUserId: "",
  clientsPatientAccountId: null,
  clientsPatientIOCId: null,
  instanceCreateDt: "",
  levelOfCare: "",
  pfrAmt: 0,
  adjustedPFR: undefined,
  doPFRAdjustmentsExist: false, // helps with simplicity since adjustedPFR can be 0 which is falsey
  doDisputesExist: false,
  timingRisk: emptyTimingRisk,
  payorRisk: emptyPayorRisk,
  riskClass: emptyRiskClass,
  typeOfCare: emptyTypeOfCare,
  admissionDt: null,
  dischargeDt: null,
  pfrDiscount: 0,
  preDiscountPfrAmt: 0,
  hasChampions: undefined,
  iocStatus: "",
  iocStatusDt: "",
  capacityToPayId: "",
  finClearanceStatus: "",
  finClearanceStatusDt: "",
  workflow: emptyWorkflow,
  isLocked: false,
  lockedByUserId: null,
  lockRequestedById: null,
  lockStatus: null,
  sectionStatus: "",
  patientInsurance: [emptyPatientInsurance],
  patientChampion: [emptyPatientChampion],
  patientPaymentProgram: [emptyPatientPaymentProgram],
  patientTransactions: [emptyTransaction],
  patientPfrAdjustments: [emptyPFRAdjustment],
  paymentDisputes: [],
  patientPaymentMethods: [emptyStripePaymentMethod],
  patientDocument: [emptyPatientDocument],
  isConverted: false,
  patientPfr: emptyPatientPfr,
  patientContactInfo: emptyPatientContactInfo,
  calculatedRemainingBalance: undefined,
  paidToPatient: false,
  isRiskClassOverriden: false,
  autoCalcEnabled: true,
  isWarmTransferCompleted: false,
  loginVerified: null,
  encounterLedger: { ledger: [], pagination: { hasMore: false } },
};

export interface patientEncounterSignUrl {
  requestId: string;
  urlToSign: string;
}

export interface patientContact {
  contactId: number;
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  primaryAddress: {
    streetAddress1: string;
    streetAddress2: string;
    city: string;
    state: {
      stateCode: string | undefined;
      stateId: number;
      stateName: string | undefined;
    };
    zipCode: number;
  };
  primPhoneNum: string;
  primPhoneType: string;
  secPhoneNum: string;
  secPhoneType: string;
  leaveVMFl: boolean;
  smsConsentFl: boolean;
}

export type patientEncounterContact = Omit<patientContact, 'title' | 'email' |'firstName' | 'lastName' | 'middleName' | 'contactId'>