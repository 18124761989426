import React from 'react';
import { Button } from '@finpay-development/shared-components';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import InfoIcon from '@mui/icons-material/Info';

interface EnrollErrorHandlerProps {
    isOpen: boolean;
    handleModalCancel: () => void;
    requirementList: any[];
    selectedPatientTab: number;
    isLoadingStatus: boolean;
  }

export function EnrollErrorHandlerModal(props: EnrollErrorHandlerProps) {
    const { isOpen, handleModalCancel, requirementList, selectedPatientTab, isLoadingStatus } = props;
    return (
        <Dialog
            scroll="paper"
            className="modal user-modal requirements-modal"
            open={isOpen}
            aria-labelledby="customized-dialog-title"
            maxWidth="md"
            test-id="enroll-error-modal"
        >
            <DialogTitle className="spa-modal-title">
                Missing Enroll Requirements
            </DialogTitle>
            <DialogContent dividers>
                <Grid container direction="row" style={{ padding: "1.5rem 0 2rem 0" }}>
                    <Typography className="pt-2" style={{ paddingTop: "0", fontSize: "16px", paddingBottom: "20px", fontFamily: "Inter", lineHeight: "25px", fontWeight: "400", color: "#808080" }}>
                        The following items need to be addressed in order to enroll in this Payment Program:
                    </Typography>
                    {selectedPatientTab === 0 && 
                        <Grid container direction="row" style={{ fontFamily: 'Inter, sans-serif', fontWeight: "400", fontSize: "16px", padding: "8px 0", alignItems: "center" }}>
                            <FiberManualRecordIcon style={{ color: "#df1e44", border: "1px solid red", borderRadius: "50%", paddingTop: "0px", marginRight: "8px", fontSize: "12px" }} className="pt-3" fontSize="small"></FiberManualRecordIcon>
                            <span>An Instance of Care must be selected.</span>
                            <Tooltip title="Click the Instance of Care tab and select an Instance of Care from the dropdown menu.">
                                <InfoIcon style={{ marginLeft: "5", color: "#afbecd" }}/>
                            </Tooltip>
                        </Grid>
                    }
                    {isLoadingStatus && selectedPatientTab > 0 &&
                        <Grid container direction="row" style={{ fontFamily: 'Inter, sans-serif', fontWeight: "400", fontSize: "16px", padding: "8px 0", alignItems: "center" }}>
                            <FiberManualRecordIcon style={{ color: "#df1e44", border: "1px solid red", borderRadius: "50%", paddingTop: "0px", marginRight: "8px", fontSize: "12px" }} className="pt-3" fontSize="small"></FiberManualRecordIcon>
                            <span>IOC and Patient data have not fully loaded.</span>
                            <Tooltip title="Data was not fully loaded. Close this window and retry convert.">
                                <InfoIcon style={{ marginLeft: "5", color: "#afbecd" }}/>
                            </Tooltip>
                        </Grid>
                    }
                    {selectedPatientTab > 0 && !isLoadingStatus && requirementList.filter((item) => item.status === false).map((requirement) => (
                        <Grid container direction="row" style={{ fontFamily: 'Inter, sans-serif', fontWeight: "400", fontSize: "16px", padding: "8px 0", alignItems: "center" }}>
                            <FiberManualRecordIcon style={{ color: "#df1e44", border: "1px solid red", borderRadius: "50%", paddingTop: "0px", marginRight: "8px", fontSize: "12px" }} className="pt-3" fontSize="small"></FiberManualRecordIcon>
                            <span>{requirement.requirementName}</span>
                            {requirement.requirementNameInfo && 
                                <Tooltip title={requirement.requirementNameInfo}>
                                    <InfoIcon style={{ marginLeft: "5", color: "#afbecd" }}/>
                                </Tooltip>
                            }
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions style={{ borderTop: "", marginTop: "0", padding: "2rem" }}>
                <Button onClick={handleModalCancel}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

