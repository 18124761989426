import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AccessControl from '../security/components/access-control';
import { RolePageNames } from '../security/model/role-page-names';
import { DetailedLedger } from '../shared/model/ledger';
import { PaymentStatus } from '../patient/components/models/payment-status';
import { PAYMENT_TYPES } from '../patient/components/models/payment-type';

export type MenuTexts = {
  menuText: string;
  deleteModalTitle: string;
};

export type ResendReceiptTexts = {
  menuText: string;
  refundMenuText: string;
  modalTitle: string;
};

type MenuAction = "refund" | "resend receipt";

type TransactionData = {
  transactionId: number;
  isPaymentTypeCard?: boolean;
  paymentStatus?: string;
  paymentTypeId: number;
  isPaymentCaptured?: boolean;
  isCaptured?: boolean;
  paymentMethodTypeId?: number;
  paymentId?: number;
  paymentReversalId?: number;
};

interface TransactionMenuProps {
  transactionData: TransactionData;
  detailedLedger: DetailedLedger[];
  reversalIdArray: number[];
  reversalPaymentOriginArray: number[];
  disputeIdArray: number[];
  disallowRefund: boolean;
  isAccountHolder: boolean;
  workflowId?: number;
  onRefund: (transactionId: number, menuTexts: MenuTexts) => void;
  onResendReceipt: (transactionId: number, isRefund: boolean) => void;
  onNavigateToDispute: (disputeId: number) => void;
  resendReceiptButton: ResendReceiptTexts;
}

const TransactionMenu: React.FC<TransactionMenuProps> = ({
  transactionData,
  detailedLedger,
  reversalIdArray,
  reversalPaymentOriginArray,
  disputeIdArray,
  disallowRefund,
  isAccountHolder,
  workflowId,
  onRefund,
  onResendReceipt,
  onNavigateToDispute,
  resendReceiptButton
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [optionTexts, setOptionTexts] = useState<MenuTexts>({
    menuText: "Refund",
    deleteModalTitle: "Refund",
  });

  const determineMenuTexts = (): MenuTexts => {
    const { 
      paymentTypeId, 
      isPaymentTypeCard, 
      paymentStatus, 
      isPaymentCaptured 
    } = transactionData;
    
    const isPaymentPending = paymentStatus === "Pending";
    
    if (paymentTypeId === PAYMENT_TYPES.AT_FACILITY.paymentTypeId) {
      return { menuText: "Remove", deleteModalTitle: "Remove Funds" };
    } else if (!isPaymentCaptured || isPaymentPending) {
      if (!isPaymentTypeCard) {
        return workflowId === 3
          ? { menuText: "Cancel", deleteModalTitle: "Cancel Transfer" }
          : { menuText: "Remove", deleteModalTitle: "Remove Funds" };
      } else {
        return { menuText: "Release", deleteModalTitle: "Release Funds" };
      }
    } else {
      return { menuText: "Refund", deleteModalTitle: "Issue Refund" };
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOptionTexts(determineMenuTexts());
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (menuAction?: MenuAction) => {
    setAnchorEl(null);
    
    if (menuAction === 'refund') {
      onRefund(transactionData.transactionId, optionTexts);
    } else if (menuAction === 'resend receipt') {
      const isRefund = reversalIdArray.includes(transactionData.transactionId);
      onResendReceipt(transactionData.transactionId, isRefund);
    }
  };

  const findCurrentTransaction = () => {
    return detailedLedger.find(
      (transaction) =>
        transaction.payment?.paymentId === transactionData.transactionId ||
        transaction.reversal?.paymentReversalId === transactionData.transactionId
    );
  };

  const isTransactionSuccessful = () => {
    const currentTransaction = findCurrentTransaction();
    return currentTransaction?.payment?.paymentStatus === PaymentStatus.success && 
      (currentTransaction?.payment?.isCaptured || currentTransaction?.reversal?.paymentReversalId);
  };

  const renderMenuButtons = () => {
    const { transactionId } = transactionData;
    const isRefund = reversalIdArray.includes(transactionId);
    
    if (disputeIdArray.includes(transactionId)) {
      return (
        <MenuItem onClick={() => onNavigateToDispute(transactionId)} className="danger">
          Dispute Response
        </MenuItem>
      );
    } else if (!reversalIdArray.includes(transactionId) && !reversalPaymentOriginArray.includes(transactionId)) {
      return (
        <>
          {!disallowRefund && (
            <MenuItem onClick={() => handleMenuClose('refund')} className="danger">
              {optionTexts.menuText}
            </MenuItem>
          )}
          {isTransactionSuccessful() && (
            <MenuItem onClick={() => handleMenuClose('resend receipt')} className="danger">
              {resendReceiptButton.menuText}
            </MenuItem>
          )}
        </>
      );
    } else {
      return (
        <MenuItem onClick={() => handleMenuClose('resend receipt')} className="danger">
          {isRefund ? resendReceiptButton.refundMenuText : resendReceiptButton.menuText}
        </MenuItem>
      );
    }
  };

  if (isAccountHolder) {
    return null;
  }

  return (
    <>
      <IconButton
        aria-haspopup="true"
        onClick={handleMenuClick}
        size="large"
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleMenuClose()}
      >
        <AccessControl
          rolePageName={RolePageNames.PatientRecords}
          actionName="Add Instance of Care"
          renderNoAccess={() => <MenuItem disabled>danger</MenuItem>}
        >
          {renderMenuButtons()}
        </AccessControl>
      </Menu>
    </>
  );
};

export default TransactionMenu;