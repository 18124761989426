import { emptyPaymentMethod, PaymentMethod } from "./payment-method";
import { emptyPaymentType, PaymentType } from "./payment-type";

export interface Payment {
  paymentId: number;
  externalCustomerId?: string;
  payorId?: number | string;
  patientId?: number;
  paymentMethodUsed?: PaymentMethod;
  patientEncounterId?: number;
  patientPaymentScheduleId?: number;
  patientPaymentMethodId?: number;
  paymentMethods?: PaymentMethod[];
  paymentAmt: number;
  automationFee?: number;
  merchantProcessingFee?: number;
  thirdPartyFee?: number;
  paymentAmtWithoutFees?: number;
  remPaymentAmt?: number;
  paymentType: PaymentType;
  paymentInitDt: string;
  authCode?: string;
  authExpirationDt?: string;
  isAuthExpired?: boolean;
  isManual?: boolean;
  isCaptured?:boolean;
  paymentStatus?: string;
  paymentStatusPending?: string;
  paymentStatusDt?: string;
  paymentAuthDt?: string;
  paymentCaptureDt?: string;
  paymentGroupId?: string;
  externalPaymentId?: string,
  externalResponse?: string;
  externalPayoutId?: string;
  isAuthOnly?: boolean;
  // mapped from transactions fields
  patientLedgerId?: number;
  transactionDesc?: string;
  paymentTypeName?: string;
  paymentStatusFailed?: string;
  paymentStatusSuccess?: boolean;
  receiptEmail?: string;
  paymentChannelId?: number;
  isRecurringDownPayment?: boolean;
  paymentReason?: string;
  paymentTypeId?: number;
  ownerId?: number;
}

export type PaymentStatusType = "Success" | "Pending" | "Failed"

export interface V2PaymentDetail {
  paymentMethodTypeId: number | null,
  paymentTypeId: number,
  paymentStatus: PaymentStatusType,
  paymentAmt: number,
  paymentId: number,
  externalResponse: string,
  externalPaymentId: string | null,
  externalPaymentMethodId: string | null,
  paymentInitDt: string,
  isCaptured: boolean | undefined,
  last4?: string,
  isFullyRefunded: boolean
}

export interface V2PFRAdjustmentDetail {
  adjustmentDt: string,
  adjustmentAmt: number,
  patientPFRAdjustmentId: number
}

export interface V2DisputeDetail {
  disputeAmt: number,
  disputeCloseDt: string | null,
  disputeReason: string,
  disputeStatus: string,
  externalDisputeId: string | null,
  paymentDisputeId: number,
  paymentMethodTypeId: number,
  paymentTypeId: number
}

export interface V2ReversalDetail {
  externalPaymentMethodId: string | null,
  externalReversalId: string | null,
  isCaptured: boolean,
  last4?: string,
  paymentId: number,
  paymentMethodTypeId: number,
  paymentReversalId: number,
  paymentStatus: PaymentStatusType,
  paymentTypeId: number,
  reversalAmt: number,
  reversalDate: string,
  reversalReason: string | null,
  reversalStatus: string
}

export const emptyPayment = {
  paymentId: 0,
  externalCustomerId: '',
  payorId: 0,
  patientId: 0,
  paymentMethodUsed: emptyPaymentMethod,
  patientEncounterId: 0,
  patientPaymentScheduleId: 0,
  patientPaymentMethodId: 0,
  paymentMethods: [emptyPaymentMethod],
  paymentAmt: 0,
  automationFee: 0,
  merchantProcessingFee: 0,
  thirdPartyFee: 0,
  paymentAmtWithoutFees: 0,
  remPaymentAmt: 0,
  paymentType: emptyPaymentType,
  paymentInitDt: '',
  authCode: '',
  authExpirationDt: '',
  isAuthExpired: false,
  isManual: false,
  paymentStatus: '',
  paymentStatusPending: '',
  paymentStatusDt: '',
  paymentAuthDt: '',
  paymentCaptureDt: '',
  paymentGroupId: '',
  externalPaymentId: '',
  externalResponse: '',
  externalPayoutId: '',
  isAuthOnly: true,
}

// "To do: Remove `reservalAmount` and use `reservalAmt` after rebuilding the transaction Java API in Node.js."
export interface PaymentReversal {
  paymentReversalId: number;
  patientId: number;
  patientEncounterId: number;
  paymentId: number;
  reversalAmount?: number;
  reversalAmt?: number;
  externalReversalId: string;
  externalLedgerId?: string | number;
  reversalDate: string;
  reversalReason?: string;
  patientLedgerId: number;
  externalPayoutId?: string | number;
  clientPayoutId?: string | number;
}

export const emptyPaymentReversal: PaymentReversal = {
  paymentReversalId: 0,
  patientId: 0,
  patientEncounterId: 0,
  paymentId: 0,
  reversalAmount: 0,
  externalReversalId: '',
  externalLedgerId: '',
  reversalDate: '',
  reversalReason: '',
  patientLedgerId: 0,
  externalPayoutId: '',
  clientPayoutId: 0,
}

export interface PaymentDispute {
  paymentDisputeId: number,
  paymentId: number,
  patientId: number,
  patientEncounterId: number,
  externalDisputeId: number,
  disputeAmt: number,
  disputeDate: string,
  disputeReason: string,
  disputeStatus: string,
  disputeCloseDt: string,
  disputeCloseReason: string,
  patientLedgerId: number,
  externalPayoutId: string | number,
}

export const emptyDispute = {
  paymentDisputeId: 0,
  paymentId: 0,
  patientId: 0,
  patientEncounterId: 0,
  externalDisputeId: 0,
  disputeAmt: 0,
  disputeDate: '',
  disputeReason: '',
  disputeStatus: '',
  disputeCloseDt: '',
  disputeCloseReason: '',
  patientLedgerId: 0,
  externalPayoutId: 0,
}

export interface MasterPatientLedger {
  amount: number;
  iocId: string;
  initiatedDate: string;
  levelOfCare: string;
  status: string;
  transactionType: string;
  type: string;
}

export interface StripeErrorMessage {
  code: string;
  declineCode: string;
  message: string;
  type: string;
}