import axios, {AxiosError} from "axios";
import { configSettings } from "../configuration/config-settings";
import { AxiosReadPayload } from "./axios-read-payload";
import { AxiosResultStatus } from "./axios-result-status";

export const axiosReadHelper = async function (
  payload: AxiosReadPayload
): Promise<AxiosResultStatus> {
  const readResult = new AxiosResultStatus();

  // standard headers for all requests are in http-interceptor.ts
  const header = payload.customHeader;

  //abort signal to cancel api calls. needed for cleaning up useEffect
  const signal = payload.signal

  // Build URL for Getting Data
  let url: string = "";
  
  if (payload.dataId <= 0 || payload.dataId === null || payload.dataId === undefined) {
    url = `${configSettings.unified_aws_app_gateway_uri}/${payload.url}`;
  } else if (payload.dataId > 0 /*&& payload.dataId!==5050*/) { // comment back in for local testing
    url = `${configSettings.unified_aws_app_gateway_uri}/${payload.url}/${payload.dataId}`;
  }/* else if (payload.dataId === 5050) {
    url = `http://localhost:8085/local/${payload.url}`
    console.log('url:', url)
  }*/ // Local testing, set dataId:5050 in service to test local endpoint

  // Execute Get And Handle Errors
  await axios
    .get(url, (header || signal) && {
      ...header && {headers: header},
      ...signal && {signal}
    })
    .then(function (response) {
      readResult.entity = response.data;
    })
    .catch(function (error: AxiosError) {
      readResult.errorMessage = error.message;
      readResult.entity = error.response?.data;
      readResult.statusCode = error.response?.status;
      // When Error Occurs Notified User Profile state there is an error
      //store.dispatch(showErrorStatus(readResult.errorMessage));
      // Log Error to Console for Now
      console.log(readResult.errorMessage);
    });

  readResult.hasErrors = readResult.errorMessage?.length > 0;
  return readResult;
};
